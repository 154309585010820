<template>
  <div>

    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-3 col-12">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama Dokter"
            @keyup="filterByName"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      class="mt-3"
      :items="items"
      :fields="fields"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? purpose == 'master' ? '30%' : '' : '' }"
        />
      </template>
      <template #cell(email_mobile_phone)="data">
        <span class="d-block">{{ data.item.email }}</span>
        <span class="d-block">{{ data.item.mobile_phone }}</span>
      </template>
      <template #cell(photo)='data'>
        <div class="d-flex justify-content-center align-items-center image-container">
          <div style='height: 50px; width: 50px; position: relative'>
            <img
              class="image"
              :src="data.item.photo"
            >
          </div>
        </div>
      </template>
      <!-- <div class="d-flex justify-content-center align-items-center image-container">
        <div style='height: 50px; width: 50px; position: relative'>
          <img
            class="image"
            :src="data.item.photo"
          >
        </div>
      </div> -->
      <template #cell(actions)='data'>
        <template v-if="purpose == 'master'">
          <div class="d-flex">
            <b-button
              size="sm"
              class="mr-1 btn-primary"
              variant="primary"
              v-b-tooltip.hover
              title="Komisi Dokter"
              @click="$router.push({ path: '/doctor/doctor-commission/' + data.item.id })"
            ><i class="fas fa-user-md px-0"></i></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-info"
              v-b-tooltip.hover
              title="Detail"
              @click="$router.push({ path: '/doctor/detail/' + data.item.id })"
            ><i class="fas fa-eye px-0"></i></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-success"
              v-b-tooltip.hover
              title="Edit"
              v-if="manipulateBtn == true"
              @click="$router.push({ path: '/doctor/edit/' + data.item.id })"
            ><i class="fas fa-edit px-0"></i></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-info"
              v-b-tooltip.hover
              title="Perjanjian"
              v-if="appointmentBtn == true"
              @click="$router.push({ path: '/doctor/doctor-appointment/' + data.item.id })"
            ><i class="fas fa-tasks px-0"></i></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-warning"
              v-b-tooltip.hover
              title="Libur"
              v-if="holidayBtn == true"
              @click="$router.push({ path: '/doctor/holiday/' + data.item.id })"
            ><i class="fas fa-calendar-times px-0"></i></b-button>
            <b-button
              size="sm"
              class="mr-1 btn-success"
              v-b-tooltip.hover
              title="Jadwal"
              v-if="scheduleBtn == true"
              @click="$router.push({ path: '/doctor/schedule/' + data.item.id })"
            ><i class="fas fa-calendar-check px-0"></i></b-button>
            <b-button
              size="sm"
              class="btn-danger"
              v-b-tooltip.hover
              title="Hapus"
              v-if="manipulateBtn ==  true"
              @click="btnDeleteOnClick(data.item.id)"
            ><i class="fas fa-trash px-0"></i></b-button>
          </div>
        </template>
        <template v-if="purpose == 'modal'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="chooseDoctor({id: data.item.id, name: `${data.item.name} - ${data.item.polyclinic_name}`})"
          > Pilih </b-button>
        </template>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>

  </div>
</template>

<script>

import module from "@/core/modules/CrudModule.js"

export default {

  props: {
    purpose: String,
    polyclinicId: Number,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "email_mobile_phone",
          label: "Email & Nomor Handphone",
          sortable: true,
        },
        {
          key: "polyclinic_name",
          label: "Poliklinik",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // access management
      manipulateBtn: false,
      scheduleBtn: false,
      holidayBtn: false,
      appointmentBtn: false,
    }
  },

  methods: {

    async pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      await this.pagination()
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`
      if (this.purpose == "polyclinic-modal") {
        filterParams += `&polyclinic_id=${this.polyclinicId}`
      }
      let response = await module.paginate(
        "doctors",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      )
      let pagination = response.meta.pagination
      this.totalRows = pagination.total

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }

      this.items = response.data
    },

    filterByName() {
      this.pagination()
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("doctors/" + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    chooseDoctor(value) {
      this.$emit("chooseDoctor", value)
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        

        if (access_right[a] == "2002") {
          this.manipulateBtn = true
        }
        if (access_right[a] == "4001") {
          this.scheduleBtn = true
        }
        if (access_right[a] == "4003") {
          this.holidayBtn = true
        }
        if (access_right[a] == "5001") {
          this.appointmentBtn = true
        }
      }
    },
  },

  mounted() {
    // Get Data
    this.pagination()
    // access management
    this.setActiveMenu()
  },

}
</script>